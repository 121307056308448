* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
font-family: 'Noto Sans TC', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif; 
*/
/*Debug*/
/* * {
    box-sizing: border-box !important;
    outline: 1px solid limegreen !important;
} */

/* html {
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1440 - 300)));
} */

h1,
h2,
h3,
h4,
h5,
h6,
ol {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 2px;
  line-height: 1.6;
  font-weight: bolder;
}

p,
a {
  font-family: 'Noto Sans TC', sans-serif;
  line-height: 1.6;
  letter-spacing: 1px;
  font-weight: bold;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.debug-sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0
}

.pre-loader {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}